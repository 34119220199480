var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Composições Por Etapa")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-hammer-wrench ")])],1)]}}])},[_c('v-list',_vm._l((_vm.toolItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.actionMenuTool(item.action)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(_vm._s(item.title))],1)],1)}),1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","dark":"","fab":""},on:{"click":function($event){return _vm.showReportAbcCompositions()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alphabetical-variant ")])],1)]}}])},[_c('span',[_vm._v("Relatório ABC")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","dark":"","fab":""},on:{"click":function($event){return _vm.showReportStageCompositions()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-list-box-outline ")])],1)]}}])},[_c('span',[_vm._v("Rel. Etapa/Composição")])]),_c('v-spacer')],1),(_vm.loading_table)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("ETAPA")]),_c('th',{staticClass:"text-right"},[_vm._v("TOTAL")]),_c('th',{staticClass:"text-right"})])]),_c('tbody',_vm._l((_vm.stages),function(stage){return _c('tr',{key:stage.id},[_c('td',[_vm._v(_vm._s(stage.code + " - " + stage.name))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.totalStage(stage)))]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.showReportCompositions(stage)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-format-list-bulleted ")])]}}],null,true)},[_c('span',[_vm._v("Relatório de Composições")])])],1)])}),0),_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-right",staticStyle:{"background":"#1976d2","color":"white"}}),_c('td',{staticClass:"text-right",staticStyle:{"background":"#1976d2","color":"white"}},[_vm._v(" Total: "+_vm._s(_vm.formatMoney(_vm.grandTotal))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"background":"#1976d2","color":"white"}})])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }