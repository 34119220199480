<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Composições Por Etapa</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" small dark fab v-bind="attrs" v-on="on">
              <v-icon>mdi-hammer-wrench </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in toolItems"
              :key="index"
              @click="actionMenuTool(item.action)"
            >
              <v-list-item-title
                ><v-icon class="mr-2"> {{ item.icon }} </v-icon
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              @click="showReportAbcCompositions()"
              class="ml-2"
              small
              dark
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-alphabetical-variant </v-icon>
            </v-btn>
          </template>
          <span>Relatório ABC</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              @click="showReportStageCompositions()"
              class="ml-2"
              small
              dark
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-list-box-outline </v-icon>
            </v-btn>
          </template>
          <span>Rel. Etapa/Composição</span>
        </v-tooltip>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-progress-linear v-if="loading_table" indeterminate></v-progress-linear>
      <v-simple-table dense>
        <thead>
          <tr>
            <th class="text-left">ETAPA</th>
            <th class="text-right">TOTAL</th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="stage in stages" :key="stage.id">
            <td>{{ stage.code + " - " + stage.name }}</td>
            <td class="text-right">{{ totalStage(stage) }}</td>
            <td class="text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click="showReportCompositions(stage)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-format-list-bulleted
                  </v-icon>
                </template>
                <span>Relatório de Composições</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="text-right" style="background: #1976d2; color: white"></td>
            <td class="text-right" style="background: #1976d2; color: white">
              Total: {{ formatMoney(grandTotal) }}
            </td>
            <td class="text-right" style="background: #1976d2; color: white"></td>
          </tr>
        </tfoot>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import service from "../service";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    costId: null,
    stages: [],
    budgetId: null,
    grandTotal: 0,
    loading_table: false,
    toolItems: [
      { title: "Exportar Excel", action: "export_excel", icon: "mdi-file-excel" },
    ],
  }),
  computed: {},

  watch: {},

  created() {
    this.costId = this.$route.params.cost_id;
    this.getStages();
  },
  methods: {
    ...mapMutations("ReportComposition", ["setStage"]),
    
    getStages() {
      this.loading_table = true;
      service
        .getStages(this.costId) //TODO Fazer a paginação
        .then((result) => {
          this.stages = result.data.data;
          this.calcGrandTotal();
        })
        .catch()
        .finally(() => {
          this.loading_table = false;
        });
    },
    actionMenuTool(action) {
      switch (action) {
        case "export_excel":
          service
            .exportStageExcel(this.costId)
            .then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", "RELATORIO_ETAPAS.xlsx");
              document.body.appendChild(fileLink);
              fileLink.click();
            })
            .catch((response) => {
              console.log(response);
            });
          break;
      }
    },

    totalStage(stage) {
      let totalStage = stage.composition.reduce((acumulator, composition) => {
        return acumulator + composition.total_with_tax * composition.pivot.quantity;
      }, 0);

      return this.formatMoney(totalStage);
    },
    calcGrandTotal() {
      this.stages.forEach((stage) => {
        let totalStage = stage.composition.reduce((acumulator, composition) => {
          return acumulator + composition.total_with_tax * composition.pivot.quantity;
        }, 0);
        this.grandTotal += totalStage;
      });
    },

    showReportCompositions(stage) {
      this.setStage(stage);
      this.$router.push("/system/report/composition/stages/compositions/" + stage.id);
    },

    showReportAbcCompositions() {
      this.$router.push("/system/report/composition/abc/compositions/" + this.costId);
    },

    showReportStageCompositions() {
      this.$router.push("/system/report/composition/stages_compositions/" + this.costId);
    },

    formatMoney(number) {
      number = this.parseNumber(number);
      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    parseNumber(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result;
    },
  },
};
</script>
